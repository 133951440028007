const COLORS = {
  eggshell: '#f6f3f0',
  black: '#292929',
  blue: '#2374e1',
  charcoal: '#292929',
  'dark-eggshell': '#f0e8df',
  'darker-eggshell': '#e8dacb',
  'darkest-eggshell': '#DEC8B2',
  'secondary-eggshell': '#F5F3EE',
  'secondary-dark-eggshell': '#E8E5E1',
  'dark-gray': 'rgba(0, 0, 0, 0.4)',
  'dark-plum': '#38202a',
  'dark-unsaturated-plum': '#301923',
  'darkest-plum': '#321B25',
  gray: 'rgba(80, 45, 60, 0.15)',
  'grey-4': '#8C8888',
  lavender: '#E8DAE0',
  'light-yellow': '#F8E5C2',
  'light-plum': '#663C4E',
  'lightest-plum': '#F0EDEE',
  plum: '#502d3c',
  'plum-100': '#F0EDEE',
  'plum-200': '#E5D8DE',
  'plum-300': '#C4A7B6',
  'plum-400': '#A17A8B',
  'plum-500': '#825568',
  'plum-600': '#663D4F',
  'plum-800': '#402530',
  'primary-plum': '#402530',
  purple: '#502d3c',
  red: '#d75e4c',
  'secondary-blue': '#5074B1',
  'secondary-yellow': '#f5e6c6',
  'secondary-tan': '#faedcd',
  silver: '#D1C5CA',
  teal: '#20968F',
  'tertiary-yellow': '#FFCC1C',
  'tertiary-gray1': '#F5F5F5',
  'tertiary-gray2': '#E0DEDE',
  'tertiary-gray3': '#C7C5C5',
  'tertiary-gray4': '#8C8888',
  'tertiary-gray5': '#595555',
  transparent: 'transparent',
  white: '#fff',
  yellow: '#FDB71C',
  inherit: 'inherit',
  'accent-yellow': '#FDB71C',
  'tertiary-warning': '#DF6F25',
  'tertiary-negative': '#C3402C',
  'tertiary-positive': '#20968F',
  'tertiary-peach': '#F5E2D8',
  'secondary-neutral': '#6684EF',
};

exports.BGS = {
  'gradient-white-to-cream': `linear-gradient(0deg, ${COLORS.eggshell} 0%, ${COLORS.white} 100%)`,
  'gradient-cream-to-white': `linear-gradient(295.86deg, ${COLORS.white} 32.36%, ${COLORS.eggshell} 70.57%)`,
  'gradient-plum': 'linear-gradient(248.66deg, #502E3C 69.66%, #3A202B 100%)',
  'gradient-yellow':
    'linear-gradient(93.22deg, #FFA41C 0%, #FDB71C 53.65%, #FFCC1C 100%);',
};

const FIXED_INCREMENT = (x) => {
  if (x < 32) {
    return 1;
  }

  if (x < 64) {
    return 2;
  }

  if (x < 200) {
    return 8;
  }

  return 25;
};

const defaultScale = { max: 1950, unit: 'px', inc: FIXED_INCREMENT };

exports.SCALES = {
  h: [
    defaultScale,
    { max: 500, unit: '%' },
    { max: 500, unit: 'vh' },
    { max: 500, unit: 'vw' },
  ],
  inset: [
    { min: -100, max: 500, unit: 'px', inc: FIXED_INCREMENT },
    { min: -1000, max: 1000, unit: '%' },
    { max: 100, unit: 'vh' },
    { max: 100, unit: 'vw' },
    { type: 'FRACTIONS', unit: '%' },
  ],
  'max-h': [defaultScale],
  'max-w': [
    defaultScale,
    { max: 100, unit: '%' },
    { type: 'FRACTIONS', unit: '%' },
  ],
  'min-h': [
    defaultScale,
    { max: 1000, unit: 'px', inc: FIXED_INCREMENT },
    { max: 100, unit: 'vh' },
  ],
  'min-w': [defaultScale, { max: 200, unit: '%' }],
  p: [
    { min: -1950, max: 500, unit: 'px', inc: FIXED_INCREMENT },
    { max: 500, unit: '%' },
    { max: 100, unit: 'vw' },
    { type: 'FRACTIONS', unit: '%' },
  ],
  m: [
    defaultScale,
    { max: 500, unit: 'px', inc: FIXED_INCREMENT },
    { max: 100, unit: '%' },
    { max: 100, unit: 'vw' },
    { type: 'FRACTIONS', unit: '%' },
  ],
  spacing: [defaultScale],
  rotate: [{ min: -360, max: 360, unit: 'deg', inc: 1 }],
  translate: [
    { min: -1950, max: 1950, unit: 'px', inc: FIXED_INCREMENT },
    { max: 100, unit: 'vw' },
    { min: -500, max: 500, unit: '%' },
    { type: 'FRACTIONS', unit: '%' },
  ],
  w: [
    defaultScale,
    { max: 1000, unit: '%' },
    { max: 200, unit: 'vw' },
    { type: 'FRACTIONS', unit: '%' },
  ],
};

exports.SCREENS = {
  xsx: { max: '767px' },
  sm: { min: '768px' },
  smx: { min: '768px', max: '1023px' },
  '-md': { max: '1023px' },
  md: { min: '1024px' },
  mdx: { min: '1024px', max: '1439px' },
  'blog-lg': { min: '1300px' },
  lg: { min: '1440px' },
  tall: { raw: '(max-height: 1200px)' },
  dk: { min: '1280px' }
};

exports.FIXED_INCREMENT = FIXED_INCREMENT;
exports.COLORS = COLORS;
exports.FIXED_INCREMENT = FIXED_INCREMENT;
